import React, {RefObject} from "react";
import {ResizeInfo, ResizerState, WDElementContainer} from "../../WDElementContainer";
import {WDElementBase, WDElementBaseData, WDElementBaseProps, WDElementBaseState} from "../../WDElementBase";
import Const from "../../../../Framework/Const";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../../../Framework/CategoryImage";
import {WDToolbarAction} from "../../../Toolbar/WDToolbarAction";
import {Util} from "../../../../Framework/Util";
import translations from "../../../../Framework/translations.json";
import {
    getDefaultFontName,
    getDefaultFontValue,
    getFontValue,
    WDWritingLineatureFontValues,
    WSDesignerWritingLineatureLineHeight
} from "./WDWritingLineatureFontValues";
import {CorrectionMarginPosition, WDWritingLineatureCorrectionMargin} from "./WDWritingLineatureCorrectionMargin";
import {WDTextbox, WDTextboxData} from "../../Textbox/WDTextbox";
import Converter from "../../../../Framework/Converter";
import {MainContext} from "../../../../_base/MainContext";
import {WSContextType} from "../../WSContext";
import _ from "lodash";
import {WorksheetItemUpdate} from "../../../Utils/WorksheetItemUpdate";
import {RESIZE_NODE} from "../../Enum/WDElementContainerResizeNode";
import {WDElementHistoryItem} from "../../../History/WDElementHistoryItem";
import {WDHistoryAction} from "../../../History/Enum/WDHistoryAction";
import {VerticalAlignment} from "../../../../Framework/Enums";
import {WDActionLogCategory} from "../../../ActionLog/WDActionLogEntry";

export enum WDWritingLineatureSize {
    size_10 = "10",
    size_4_4_4 = "4-4-4",
    size_6_6_6 = "6-6-6",
    size_8_4 = "8-4",
    size_8_8_8 = "8-8-8",
    size_12_6 = "12-6",
    size_16_8 = "16-8"
}

export class WDWritingLineatureData extends WDElementBaseData {
    id?: number
    text: string
    data: WDTextboxData
    image: string
    rows: number
    fontName: string
    individualFontSize?: number
    lineature: WDWritingLineatureSize
    correctionMargin: WDWritingLineatureCorrectionMargin
    additionalRowSpacing: number
    lineColor?: string
    showSolution: boolean

    constructor(
        text: string,
        data: WDTextboxData,
        image: string,
        fontName: string,
        lineature: WDWritingLineatureSize,
        rows: number,
        correctionMargin: WDWritingLineatureCorrectionMargin,
        additionalRowSpacing: number,
        lineColor?: string,
        individualFontSize?: number) {

        super()
        this.text = text
        this.data = data
        this.image = image
        this.fontName = fontName
        this.lineature = lineature
        this.rows = rows
        this.correctionMargin = correctionMargin
        this.additionalRowSpacing = additionalRowSpacing
        this.lineColor = lineColor
        this.showSolution = true
        this.individualFontSize = individualFontSize
    }

    static getImageCategory = (lineatureSize: string) => {
        if (lineatureSize === WDWritingLineatureSize.size_10) {
            return ImageCategory.WRITING_LINEATURE_THUMBNAIL_STANDARD
        } else if (lineatureSize === WDWritingLineatureSize.size_8_4 || lineatureSize === WDWritingLineatureSize.size_12_6 || lineatureSize === WDWritingLineatureSize.size_16_8) {
            return ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY
        } else {
            return ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY
        }
    }

    static serialize = (data: WDWritingLineatureData) => {
        let newData = {...data as WDWritingLineatureData}

        // Map keys and images because in database keys are saved in state the path is saved
        let imageMiddle = CategoryImageValue.getKeyOfImage(ImageCategory.WRITING_LINEATURE, newData.image + "-m.svg")
        if (imageMiddle) {
            newData.image = imageMiddle.substring(0, imageMiddle.lastIndexOf("-M"))
        }

        return JSON.stringify(newData)
    }
}

interface WDWritingLineatureProps extends WDElementBaseProps {
    data: WDWritingLineatureData
    isIndependentElement: boolean
    fixedPaddingSides?: number // Used if left and right padding should match other elements (e.q. text exercise)

    onFocus?: () => void
}

interface WDWritingLineatureState extends WDElementBaseState {
    resizerState: ResizerState
    textboxRef: RefObject<WDTextbox>
    containerHeight: number
}

export class WDWritingLineature extends WDElementBase<WDWritingLineatureProps, WDWritingLineatureState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    defaultTextPadding: number = 22 // padding to prevent writing in images left and right

    // minWidth = 300 to include also the size of both images left and right
    resizeNodesLineature: ResizeInfo =
        new ResizeInfo(false, false, false, false, true, true, true, true,
            200, Const.MaxElementSize, getDefaultFontValue().rowSpacing, Const.MaxElementSize)
    resizeOptions = {showResizer: true, showError: true}

    constructor(props: WDWritingLineatureProps) {
        super(props);

        // change resize infos depending on context
        if (this.props.context === WSContextType.writing_course_child) {
            this.resizeNodesLineature = new ResizeInfo(false, false, false, false, true, false, true, false,
                200, Const.WorksheetDefaultPageHeight, getDefaultFontValue().rowSpacing, Const.WorksheetDefaultPageHeight)

            this.resizeOptions = {showResizer: false, showError: false}
        }

        this.state = {
            isEdited: false,
            resizerState: ResizerState.NONE,
            showNonPrintableObjects: this.props.showNonPrintableObjects,
            elementRef: React.createRef(),
            textboxRef: React.createRef(),
            containerHeight: 0
        }
    }

    componentDidMount() {
        let update = new WorksheetItemUpdate(this.props.id, {})
        let height = this.recalculateContainerSize(this.props.data, false, false)

        this.setTextboxHeight(this.props.data)
        if (Util.roundTo(this.props.element.height, 2) !== height) {
            update.value.height = height
        }

        if (Object.keys(update.value).length > 0) {
            this.props.onUpdateElement(update,
                {actionCategory: WDActionLogCategory.resize})
        }

        this.state.textboxRef.current?.setTextOverflow()
        this.props.onElementLoaded?.(this.props.id)
    }

    shouldComponentUpdate(nextProps: Readonly<WDWritingLineatureProps>, nextState: Readonly<WDWritingLineatureState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    static getImageNameByKey = (lineatureSize: string, jsonData: any) => {
        let imageKey = "D-AT-" + lineatureSize + "-STANDARD"

        if (jsonData.image) {
            imageKey = jsonData.image
        }

        return imageKey.includes("THUMB") ? WDWritingLineature.replaceThumbImage(imageKey, lineatureSize) : WDWritingLineature.replaceCutKeyByImage(imageKey)
    }

    static getDefaultWidth = () => {
        return 500;
    }
    static getDefaultHeight = (lineatureSize: WDWritingLineatureSize | undefined) => {
        let fontValue = getFontValue(getDefaultFontName(), lineatureSize || WDWritingLineatureSize.size_10)
        if (fontValue === undefined) {
            fontValue = getDefaultFontValue()
        }
        return fontValue.rowHeight
    }
    static getDefaultContent = (): string => {
        let data = new WDWritingLineatureData("", WDTextboxData.defaultContent(),
            "d-at-10-standard", getDefaultFontName(), WDWritingLineatureSize.size_10, 1,
            new WDWritingLineatureCorrectionMargin(false, CorrectionMarginPosition.left, "0px"), 0)

        return WDWritingLineatureData.serialize(data)
    }

    getRowHeight = (data: WDWritingLineatureData) => {
        const fontValue = getFontValue(data.fontName, data.lineature)
        if (fontValue) {
            return fontValue.rowHeight + fontValue.rowSpacing + data.additionalRowSpacing
        }

        return 0
    }
    getRowAmount = (newContainerHeight: number): number => {
        let fontValue = getFontValue(this.props.data.fontName, this.props.data.lineature)

        if (fontValue) {
            // Add and remove additional values to row size because rowHeight is not right for bottom and top
            let calculatedHeight = newContainerHeight -
                (2 * Const.ELEMENT_PADDING) +
                (fontValue.rowSpacing + this.props.data.additionalRowSpacing)

            return Math.max(1, Math.round(calculatedHeight / this.getRowHeight(this.props.data)))
        }

        return 1
    }
    getContainerHeight = (data: WDWritingLineatureData, newRowAmount?: number) => {
        const rows = newRowAmount || Math.max(1, data.rows)
        const fontValue = getFontValue(data.fontName, data.lineature)
        if (fontValue) {
            let containerSize = rows * this.getRowHeight(data)
            // add another row-spacing for spacing before first row
            containerSize += (2 * Const.ELEMENT_PADDING)
            // remove one additional row spacing because last row is not affected by it - margin to container stays the same
            containerSize -= (fontValue.rowSpacing + data.additionalRowSpacing)

            return containerSize
        }

        return 0
    }
    setTextboxHeight = (data: WDWritingLineatureData) => {
        let fontValue = getFontValue(data.fontName, data.lineature)
        if (fontValue) {
            let additionalSpacing = data.lineature !== WDWritingLineatureSize.size_10
                ? this.getAdditionalRowHeightLineValues(fontValue)?.marginTop
                : 0

            if (additionalSpacing !== undefined) {
                const lineatureTextbox = document.getElementById("txt-line-txt-" + this.props.id) as HTMLElement
                if (lineatureTextbox !== null) {
                    lineatureTextbox.style.setProperty("--additionalMargin",
                        (additionalSpacing * (-1)) + "px")
                }
            }
        }
    }
    recalculateSize = (width: number, height: number, adjustSize: boolean) => {
        let update = new WorksheetItemUpdate(this.props.id, {
            width: width, height: height
        })

        let newData = {...this.props.data}
        newData.rows = this.getRowAmount(height)
        update.value.content = this.serializeElementData(newData)

        if (adjustSize) {
            update.value.height = this.getContainerHeight(newData, newData.rows)
        }

        return update
    }

    doAction = (action: WDToolbarAction, data?: any) => {
        let newData = {...this.props.data}
        let update = new WorksheetItemUpdate(this.props.id, {})

        switch (action) {
            case WDToolbarAction.ADD_ROW:
                newData.rows = newData.rows + 1
                update.value.height = this.recalculateContainerSize(newData, false, false)
                break

            case WDToolbarAction.DELETE_ROW:
                newData.rows = Math.max(1, newData.rows - 1)
                update.value.height = this.recalculateContainerSize(newData, false, false)
                break

            case WDToolbarAction.CHANGE_GRAPHIC:
                if (data && data["image"]) {
                    newData.image = WDWritingLineature.replaceThumbImage(data["image"], this.props.data.lineature)

                    // #3113 make sure that image will not be colored when it is not standard
                    if (!newData.image.toString().toLowerCase().includes("standard")) {
                        newData.lineColor = undefined
                    }

                    update.value.height = this.recalculateContainerSize(newData, false, false)
                    this.setTextboxHeight(newData)
                }
                break

            case WDToolbarAction.CHANGE_LINEATURE_SIZE:
                if (data) {
                    let sizeOld = newData.lineature
                    newData.lineature = data["lineatureSize"]

                    // If 10 is chosen, only standard lineature is available
                    if (newData.lineature === "10") {
                        newData.image = "d-at-10-standard"
                        newData.additionalRowSpacing = 0

                    }
                    // If old size is 10, only standard lineature is available for other sizes
                    else if (sizeOld === WDWritingLineatureSize.size_10) {
                        newData.image = newData.image.replace("STANDARD1", "STANDARD")
                        newData.image = newData.image.replace("STANDARD2", "STANDARD")
                        newData.image = newData.image.replace("STANDARD3", "STANDARD")
                        newData.image = newData.image.replace("STANDARD4", "STANDARD")
                        newData.image = newData.image.replace("STANDARD5", "STANDARD")
                        newData.image = newData.image.replace("STANDARD6", "STANDARD")
                        newData.image = newData.image.replace("STANDARD7", "STANDARD")
                        newData.image = newData.image.replace(sizeOld, newData.lineature)
                    } else {
                        newData.image = newData.image.replace(sizeOld, newData.lineature)
                        newData.individualFontSize = undefined
                    }

                    // recalculate new height
                    update.value.height = this.recalculateContainerSize(newData, true, false)
                    this.setTextboxHeight(newData)
                }
                break

            case WDToolbarAction.CORRECTION_MARGIN:
                if (data && data["correctionMargin"]) {
                    newData.correctionMargin = data["correctionMargin"]
                }
                break

            case WDToolbarAction.ADD_ROW_SPACING:
                if (data && data["additionalRowSpacing"] !== undefined) {
                    newData.additionalRowSpacing = Math.round(Converter.mmToPx(data["additionalRowSpacing"]))

                    update.value.height = this.recalculateContainerSize(newData, false, false)
                    this.setTextboxHeight(newData)
                }
                break

            case WDToolbarAction.LINE_COLOR:
                if (data) {
                    newData.lineColor = data["lineColor"]
                }
                break

            case WDToolbarAction.FONT_TYPE:
                if (data && data["name"]) {
                    newData.fontName = data["name"]
                    let u = this.state.textboxRef.current?.doAction(action, data)
                    if (u && u.value.content) {
                        newData.data = JSON.parse(u.value.content)
                    }
                }
                break

            case WDToolbarAction.FONT_SIZE:
                // only do something if lineature size === "10" otherwise do nothing because font size change is not allowed
                if (newData.lineature === WDWritingLineatureSize.size_10 && data && data["size"]) {
                    newData.individualFontSize = data["size"]
                }
                break

            case WDToolbarAction.SUBSCRIPT:
                // do nothing because line height of lineature would be messy
                break

            case WDToolbarAction.SUPERSCRIPT:
                // do nothing because line height of lineature would be messy
                break

            case WDToolbarAction.CONVERT_TO_SYLLABLE:
                if (data && data["syllableActivated"] !== undefined) {
                    this.state.textboxRef.current?.textToSyllableText(data["syllableActivated"]).then(
                        (textboxData) => {
                            newData.data.text = WDTextboxData.serialize(textboxData)
                            newData.data = textboxData

                            update = new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
                            this.props.onUpdateElement(update, {
                                historyAction: WDHistoryAction.CONTENT_CHANGED,
                                actionCategory: WDActionLogCategory.syllable
                            })
                        }
                    )
                }
                break

            default:
                let u = this.state.textboxRef.current?.doAction(action, data)
                if (u && u.value.content) {
                    let o = JSON.parse(u.value.content) as WDTextboxData
                    Object.keys(o).forEach(key => {
                        newData.data[key] = o[key]
                    })
                }
        }

        if (action !== WDToolbarAction.CONVERT_TO_SYLLABLE) {
            update.value.content = this.serializeElementData(newData)
        }

        return update
    }
    onAddVariableText = (onInput: (e: Event) => void) => {
        return this.state.textboxRef.current?.onAddVariableText(onInput)
    }

    /**
     * Replaces the thumb by the matching graphic
     * @param thumbImageKey to find thumb image svg
     * @param newLineatureSize is the size of the graphic; needed to replace thumb image size
     */
    static replaceThumbImage = (thumbImageKey: string, newLineatureSize: string) => {
        let thumbSize = "4-4-4"
        let category = ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY

        if (thumbImageKey.includes("10-")) {
            thumbSize = "10"
            category = ImageCategory.WRITING_LINEATURE_THUMBNAIL_STANDARD
        } else if (thumbImageKey.includes("8-4")) {
            thumbSize = "8-4"
            category = ImageCategory.WRITING_LINEATURE_THUMBNAIL_UNEVENLY
        }

        let thumbImage = CategoryImageValue.getImageByKey([category], thumbImageKey)
        let newImage = thumbImage.substring(0, thumbImage.lastIndexOf("-thumb.svg"))
        return newImage.replace(thumbSize, newLineatureSize)
    }
    /**
     * Removes specific position (l, r, m) of lineature graphic to save generic lineature graphic name
     */
    static replaceCutKeyByImage = (imageKey: string) => {
        let newImage = CategoryImageValue.getImageByKey([ImageCategory.WRITING_LINEATURE], imageKey + "-M")
        return newImage.substring(0, newImage.lastIndexOf("-m.svg"))
    }

    getDataFromTextUpdate = (update: WorksheetItemUpdate) => {
        let itemUpdate = new WorksheetItemUpdate(this.props.id, {})
        let newData = {...this.props.data}

        if (update.value.content) {
            newData.data = JSON.parse(update.value.content)
        }

        if (update.value.height) {
            itemUpdate.value.height = update.value.height + (this.getRowHeight(newData) / 2)

            // Change row amount based on new height and recalculate height based on rows
            newData.rows = this.getRowAmount(itemUpdate.value.height)
            itemUpdate.value.height = this.getContainerHeight(newData)
        }

        itemUpdate.value.content = this.serializeElementData(newData)

        return itemUpdate
    }
    pushHistory = (before: WorksheetItemUpdate[], after: WorksheetItemUpdate[]) => {
        this.props.pushHistory([this.getDataFromTextUpdate(before[0])], [this.getDataFromTextUpdate(after[0])])
    }
    updateHistory = (value: WDElementHistoryItem) => {
        this.props.updateHistory(new WDElementHistoryItem([this.getDataFromTextUpdate(value.updates[0])]))
    }

    updateTextData = async (update: WorksheetItemUpdate) => {
        this.context.log.info("WDWritingLineature: Update text data...")
        this.context.log.flush()

        let itemUpdate = this.getDataFromTextUpdate(update)
        this.props.onUpdateElement(itemUpdate, {
            historyAction: update.value.height ? WDHistoryAction.RESIZE : undefined,
            actionCategory: WDActionLogCategory.content
        })
    }
    onEditElement = async (editMode: boolean) => {
        await this.state.textboxRef.current?.onEditElement(editMode)

        if (editMode) {
            this.state.textboxRef.current?.setFocus()
        } else {
            this.state.elementRef.current?.onStopEdit()
        }

        this.props.onElementEdit?.(this.props.id, editMode)
    }

    recalculateContainerSize = (data: WDWritingLineatureData, removeFontSizeStyle: boolean, addToHistory: boolean) => {
        let newHeight = this.getContainerHeight(data)

        if (removeFontSizeStyle) {
            let textboxElement = document.getElementById("txt-line-txt-" + this.props.id)
            textboxElement && Util.removeStyleFromElementsRecursive(textboxElement, "font-size", false, [])
        }

        return Util.roundTo(newHeight, 2)
    }

    onSelect = (id: string, newState: boolean, resetSelection: boolean) => {
        // Call onSelect with own ID (not the ID provided, which is the ID of the textbox)
        this.props.onElementSelect?.(this.props.id, newState, resetSelection)
    }

    // Overridden methods
    getNameConfigInstances = (): number[] => {
        if (this.state.textboxRef.current) {
            return this.state.textboxRef.current.getNameConfigInstances()
        } else {
            return []
        }
    }
    serializeElementData = (data: WDElementBaseData): string => {
        return WDWritingLineatureData.serialize(data as WDWritingLineatureData)
    }
    getAdditionalRowHeightLineValues = (fontValue: WDWritingLineatureFontValues): WSDesignerWritingLineatureLineHeight | undefined => {
        return fontValue.lineHeight.find(value => {
            return value.additionalRowSpacing === this.props.data.additionalRowSpacing
        })
    }
    setResizeState = (state: boolean, nodeType?: RESIZE_NODE) => {
        this.state.elementRef.current?.setResizeState(state, nodeType)

        let update = new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(this.props.data),
            width: this.props.element.width,
            height: this.props.element.height,
            resized: state
        })

        if (nodeType === undefined) {
            let newData = {...this.props.data}
            update.value.height = this.getContainerHeight(newData, newData.rows)
        }
        return update
    }
    onAutoResize = () => {
        this.state.textboxRef.current?.onAutoResize()
        this.forceUpdate()
    }
    onAutoResizerStateChanged = (resizerState: ResizerState) => {
        this.setState({resizerState: resizerState === undefined ? ResizerState.NONE : resizerState})
    }

    render() {
        // if element is marked as deleted, do not render
        if (this.props.element.deleted) {
            return <></>
        }

        let data = this.props.data
        this.context.log.debug("Getting font value for " + data.fontName + " and " + data.lineature + "...")
        const fontValue = getFontValue(data.fontName, data.lineature)
        if (fontValue === undefined) {
            return <></>
        }

        let image = data.image.toLowerCase()
        let lineHeightForAdditionalRowSpacing = this.getAdditionalRowHeightLineValues(fontValue)
        if (lineHeightForAdditionalRowSpacing === undefined) {
            lineHeightForAdditionalRowSpacing = fontValue.lineHeight[0]
        }

        let txtMarginTop = lineHeightForAdditionalRowSpacing.marginTop
        let txtPaddingTop = 0

        // 10th lineature needs additional paddingTop
        if (fontValue.lineatureSize === "10") {
            txtPaddingTop = Const.ELEMENT_PADDING / 2
        }

        let rowPaddingLeft = Const.ELEMENT_PADDING - 2, rowPaddingRight = Const.ELEMENT_PADDING - 2
        let rowMargin = Const.ELEMENT_PADDING

        // check if lineature color must be applied
        let colorClass = ""
        if (data.lineColor !== undefined) {
            colorClass = " svg-color-" + data.lineColor.toUpperCase()
        }

        // calculate number of rows
        let rows: JSX.Element[] = []

        // render rows
        for (let i = 0; i < data.rows; i++) {
            // for space between = normal row-spacing + additional user selected row spacing for all rows after the first
            if (i > 0) {
                rowMargin = fontValue.rowSpacing + data.additionalRowSpacing
            }

            // Last row gets an additional bottom spacing
            let marginBottom = 0
            if (i === (data.rows - 1)) {
                marginBottom = Const.ELEMENT_PADDING
            }

            rows.push(
                <div className={"ws-designer-writing-lineature-row"}
                     style={{
                         marginTop: rowMargin,
                         paddingBottom: marginBottom,
                         paddingRight: rowPaddingRight,
                         paddingLeft: rowPaddingLeft
                     }}
                     key={"line-img-" + this.props.id + "-" + i}>

                    <img className={"ws-designer-writing-lineature-img" + colorClass}
                         src={process.env.PUBLIC_URL + ImagePath.getLineatureUrl() + image + "-l.svg"}
                         style={{height: fontValue.rowHeight}}
                         id={"line-img-left-" + this.props.id + "-" + i}
                         key={"line-img-left-" + this.props.id + "-" + i}
                         alt={this.context.translate(translations.lineature.lineature)}/>

                    <img id={"line-img-middle-" + this.props.id + "-" + i}
                         className={"ws-designer-writing-lineature-img-middle" + colorClass}
                         style={{height: fontValue.rowHeight}}
                         key={"line-img-middle-" + this.props.id + "-" + i}
                         src={process.env.PUBLIC_URL + ImagePath.getLineatureUrl() + image + "-m.svg"}
                         alt={this.context.translate(translations.lineature.lineature)}/>

                    <img className={"ws-designer-writing-lineature-img" + colorClass}
                         src={process.env.PUBLIC_URL + ImagePath.getLineatureUrl() + image + "-r.svg"}
                         style={{height: fontValue.rowHeight}}
                         key={"line-img-right-" + this.props.id + "-" + i}
                         id={"line-img-right-" + this.props.id + "-" + i}
                         alt={this.context.translate(translations.lineature.lineature)}/>
                </div>)
        }

        let lineature = <div className={"ws-designer-writing-lineature print"}>

            {/* Text area overlay */}
            <div className={"ws-designer-writing-lineature-textbox"}>
                <WDTextbox
                    style={{
                        lineHeight: lineHeightForAdditionalRowSpacing.lineHeight + "px",
                        height: this.getContainerHeight(this.props.data) - txtMarginTop,
                        marginTop: txtMarginTop,
                        paddingTop: txtPaddingTop,
                        fontFamily: data.fontName,
                        fontSize: data.individualFontSize ? data.individualFontSize + "pt" : fontValue.fontSize,
                        paddingLeft: (data.correctionMargin.showCorrectionMargin && data.correctionMargin.position === CorrectionMarginPosition.left)
                            ? +(data.correctionMargin.margin.replaceAll("px", "")) + 10
                            : rowPaddingLeft + this.defaultTextPadding,
                        paddingRight: (data.correctionMargin.showCorrectionMargin && data.correctionMargin.position === CorrectionMarginPosition.right)
                            ? +(data.correctionMargin.margin.replaceAll("px", "")) + 5
                            : rowPaddingRight + this.defaultTextPadding
                    }}
                    className={"ws-designer-textbox ws-designer-writing-lineature-text"}
                    isIndependentElement={false}
                    id={"line-txt-" + this.props.id}
                    element={this.props.element}
                    hasResizeOnCreate={false}
                    hasSpellCheck={true}
                    fireUpdateOnBlur={true}
                    isReadOnly={this.props.isReadOnly}
                    onFocus={this.props.onFocus}
                    resizeOptions={this.resizeOptions}
                    showNonPrintableObjects={this.state.showNonPrintableObjects}
                    onElementSelect={this.onSelect}
                    onUpdateElement={(update) => this.updateTextData(update)}
                    onAutoResizerStateChanged={this.onAutoResizerStateChanged}
                    solutionForceMode={this.props.solutionForceMode}
                    inPresentationMode={this.props.inPresentationMode}
                    data={this.props.data.data === undefined ? new WDTextboxData(this.props.data.text, false, false, VerticalAlignment.top) : this.props.data.data}
                    updateHistory={this.updateHistory}
                    pushHistory={this.pushHistory}
                    context={this.props.context}
                    ref={this.state.textboxRef}
                />
            </div>

            {/* Image rows */}
            {rows}

            {/* Correction margin */}
            {data.correctionMargin.showCorrectionMargin &&
                <div className={"ws-designer-writing-lineature-correction-margin"}
                     style={{
                         height: this.props.element.height - (2 * Const.ELEMENT_PADDING),
                         marginTop: Const.ELEMENT_PADDING,
                         right: data.correctionMargin.position === CorrectionMarginPosition.right
                             ? data.correctionMargin.margin
                             : "unset",
                         left: data.correctionMargin.position === CorrectionMarginPosition.left
                             ? data.correctionMargin.margin
                             : "unset"
                     }}/>}

        </div>

        let resizerState = this.state.resizerState
        if (resizerState === ResizerState.OK && !this.props.element.selected) {
            resizerState = ResizerState.NONE
        } else if (!this.state.showNonPrintableObjects) {
            resizerState = ResizerState.NONE
        }

        if (this.props.isIndependentElement) {

            return <WDElementContainer id={this.props.id}
                                       element={this.props.element}
                                       hasResizeOnCreate={false}
                                       onEdit={this.onEditElement}
                                       resizeInfo={this.resizeNodesLineature}
                                       resizerState={resizerState}
                                       renderWrapper={true}
                                       onUnlockElement={this.unlockElement}
                                       onResizeStateChanged={this.props.onResizeStateChanged}
                                       onResizeElement={this.props.onElementResize}
                                       isEditModeAllowed={this.isEditModeAllowed}
                                       isReadOnly={this.props.isReadOnly}
                                       onContextMenu={this.props.onContextMenu}
                                       onAutoResize={this.onAutoResize}
                                       ref={this.state.elementRef}
            >
                {lineature}
            </WDElementContainer>
        }

        return lineature
    }
}
