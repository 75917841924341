import {Coords} from "../../Framework/Coords";
import {ElementLayout} from "../Elements/WDElementContainer";
import {WSPageFormat} from "../../Framework/Enums";
import {Util} from "../../Framework/Util";
import {WorksheetItem} from "../../_model/WorksheetItem";
import {Draw, DrawBorder, DrawBorderStyle, DrawLineStyle} from "../../Framework/Draw";
import Const from "../../Framework/Const";
import _ from "lodash";
import {WorksheetItemUpdate} from "./WorksheetItemUpdate";
import {WorksheetItemHistory} from "../History/WorksheetItemHistory";
import {WorksheetPage} from "../../_model/WorksheetPage";

export enum PositioningLineType {
    HORIZONTAL_START,
    HORIZONTAL_END,
    VERTICAL_START,
    VERTICAL_END
}
export enum PositioningMode {
    MOVE,
    RESIZE
}
export class PositioningLine {
    type: PositioningLineType
    pos1: Coords
    pos2: Coords

    constructor(pos1: Coords, pos2: Coords, type: PositioningLineType) {
        this.pos1 = pos1
        this.pos2 = pos2
        this.type = type
    }
}
export class PositioningLineCheck {
    left: boolean = true
    top: boolean = true
    right: boolean = true
    bottom: boolean = true

    constructor(left: boolean, top: boolean, right: boolean, bottom: boolean) {
        this.left = left
        this.top = top
        this.right = right
        this.bottom = bottom
    }
}

export class WDUtils {

    static updateWorksheetItems(elements: WorksheetItem[], updates: WorksheetItemUpdate[], history: WorksheetItemHistory[]): WorksheetItem[] {
        return elements.map(e => {
            let cloned = WorksheetItem.clone(e)
            let changed = false
            let historyItem = new WorksheetItemHistory(e.itemKey, {}, {})

            // Iterate through children, if one changed create a history entry for the current item with positioning values
            // History entry of child element was added by the recursive call
            if (e.children !== undefined && e.children.length > 0) {
                let children = WDUtils.updateWorksheetItems(e.children, updates, history)

                if (!_.isEqual(children, e.children)) {
                    changed = true
                    cloned.changed = true
                    cloned.children = [...children]

                    historyItem.before!.width = cloned.width
                    historyItem.before!.height = cloned.height
                    historyItem.before!.posX = cloned.posX
                    historyItem.before!.posY = cloned.posY
                }
            }

            // Get item updates for current item
            let itemUpdates = updates.filter(u => u.itemKey === e.itemKey)
            if (itemUpdates && itemUpdates.length > 0) {
                // console.log("Updating item " + e.itemKey + " with " + itemUpdates.length + " updates")

                // Apply all item updates for the current item
                itemUpdates.forEach(u => {
                    let attrChanged = false

                    Object.keys(u.value).filter(k => k !== "children").forEach(k => {
                        // Add change to history even when the values are the same so we can undo the change
                        // e.g. width and height on resize need to be tracked before and after the resize state change
                        // id ist no persisted in history
                        if (k !== "id" && k !== "changed" && k !== "selected") {
                            historyItem.before![k] = cloned[k]
                            historyItem.after![k] = u.value[k]
                        }

                        if (!_.isEqual(cloned[k], u.value[k])) {
                            // console.log("Item " + e.itemKey + " changed attribute " + k + ": " + cloned[k] + " => " + u.value[k])

                            cloned[k] = u.value[k]
                            attrChanged = attrChanged || (k !== "id")
                        }
                    })
                    changed = attrChanged || changed
                })
                // console.log("Item " + e.itemKey + " changed = " + changed)

                // evaluate changed flag if it wasn't provided with an update
                let changedProvided = itemUpdates.find(u => Object.keys(u.value).find(k => k === "changed") === undefined)
                if (changedProvided) {
                    cloned.changed = cloned.changed || changed
                }
            }

            // Add change to history when at least 1 attribute was changed
            if (cloned.changed && (Object.keys(historyItem.before!).length > 0 || Object.keys(historyItem.after!).length > 0)) {
                history.push(historyItem)
            }

            // return cloned item if changed otherwise return original (unchanged) item
            return (changed ? cloned : e)
        }) as WorksheetItem[]
    }

    static getWorksheetItemsByPage = (elements: WorksheetItem[], pageKey: string): WorksheetItem[] => {
        return elements.filter(e => e.worksheetPageKey === pageKey && !e.deleted)
    }
    static getNumberOfSelectedWorksheetItems = () => {
        return document.querySelectorAll(".ws-designer-element-selected").length
    }

    static getWorksheetByCoords(coords: Coords) : HTMLElement | null {
        const elements = document.elementsFromPoint(coords.x, coords.y);
        return elements.find(e => e.className === "ws-designer-sheet-workspace") as HTMLElement
    }
    static getSheetByCoords(coords: Coords) {
        const workspace = WDUtils.getWorksheetByCoords(coords)
        if (workspace) {
            return workspace.querySelector("div.ws-designer-sheet")
        }
    }
    static getPageElementByCoords(coords: Coords, pages: WorksheetPage[]) {
        let pageElement = WDUtils.getSheetByCoords(coords)
        if (pageElement) {
            let key = pageElement.id

            return this.getWorksheetPageByHtmlElementId(key, pages)
        }
    }
    static getWorksheetPageByHtmlElementId = (key: string | number | undefined, pages: WorksheetPage[]) => {
        let id = 0

        if (_.isNumber(key)) {
            id = key
        } else if (_.isString(key)) {
            id = +(key.substring(key.lastIndexOf("-") + 1))
        }

        let page = pages.find(p => p.id === id)
        if (page === undefined) {
            if (_.isString(key)) {
                key = key.replace("ws-designer-sheet-", "")
            }
            page = pages.find(p => p.key === key)
        }

        return page
    }
    static getWorksheetItemByCoords(coords: Coords) {
        const elements = document.elementsFromPoint(coords.x, coords.y);
        return elements.find(e => e.className === "ws-designer-element") as HTMLElement
    }
    static getSheetById(itemKey: string) {
        const refItem = document.getElementById(itemKey + "-container")
        if (refItem === null || refItem === undefined) {
            return null
        }

        return Util.getParentByClass(refItem, "ws-designer-sheet")
    }

    static getBoundingRectOfElement(element: WorksheetItem) {
        if (element.rotation === 0) {
            return ElementLayout.createFromWorksheetItem(element)
        }
        else {
            let phi = Util.degToRad(element.rotation)
            let as = Math.abs(Math.sin(phi))
            let cs = Math.abs(Math.cos(phi))

            let w = element.width * cs + element.height * as
            let h = element.width * as + element.height * cs

            return new ElementLayout(element.posX, element.posY, w, h)
        }
    }
    static getSizeOfElementInBoundingRect(width: number, height: number, angle: number) {
        let phi = Util.degToRad(angle)
        let as = Math.abs(Math.sin(phi))
        let cs = Math.abs(Math.cos(phi))

        let w = -(height * as - width * cs) / (Math.pow(cs, 2) - Math.pow(as, 2))
        let h = (height * cs - width * as) / (Math.pow(cs, 2) - Math.pow(as, 2))

        return new Coords(w, h)
    }

    /**
     * Worksheet conversions
     */
    static convertRectToAnchorObjectCoordinates = (id: string, layout: ElementLayout) => {
        const anchorRect = WDUtils.getWorksheetItemAnchorObjectCoordinates(id)

        return new ElementLayout(layout.left - anchorRect.left, layout.top - anchorRect.top, layout.width, layout.height)
    }
    static convertCoordsToAnchorObjectCoordinates = (id: string, pos: Coords) => {
        const anchorRect = WDUtils.getWorksheetItemAnchorObjectCoordinates(id)

        return new Coords(pos.x - anchorRect.left, pos.y - anchorRect.top)
    }
    static getWorksheetItemAnchorObjectCoordinates = (id: string) => {
        const container = Util.getRequiredElementById(id + "-container")

        let anchor = Util.getParentByClass(container, "ws-designer-element-group")
        if (anchor == null) {
            anchor = Util.getParentByClass(container, "ws-designer-text-exercise-section-images")
            if (anchor == null) {
                anchor = Util.getParentByClass(container, "ws-designer-sheet")
                if (anchor === null || anchor === undefined) {
                    throw new Error("Worksheet not found")
                }
            }
        }

        return ElementLayout.createFromDOMRect(anchor!.getBoundingClientRect())
    }
    static convertToSheetCoordinates = (pageKey: string, pos: Coords) => {
        let rect = document.getElementById(pageKey)?.getBoundingClientRect()
        if (rect) {
            return new Coords(pos.x - rect!.left, pos.y - rect!.top)
        }

        return pos
    }
    static getWorksheetPageWidth = (format: WSPageFormat, portrait: boolean) => {
        switch (format) {
            case WSPageFormat.A5:
                return portrait ? 559 : 793;
            case WSPageFormat.A4:
                return portrait ? 794 : 1123;
            case WSPageFormat.A3:
                return portrait ? 1123 : 1587;
        }
    }
    static getWorksheetPageHeight = (format: WSPageFormat, portrait: boolean) => {
        switch (format) {
            case WSPageFormat.A5:
                return portrait ? 793 : 559;
            case WSPageFormat.A4:
                return portrait ? 1123 : 793;
            case WSPageFormat.A3:
                return portrait ? 1587 : 1122;
        }
    }

    /**
     * Thumbnail generation
     */
    static generateThumbnailElement = (element: HTMLElement) : HTMLElement | null => {
        if (element === null) {
            return null
        }

        let clonedElement: HTMLElement = element.cloneNode(true) as HTMLElement
        clonedElement.id = "ws-designer-sheet-thumbnail"
        clonedElement.className = clonedElement.className + " ws-designer-thumbnail-capture"

        // set transform undefined otherwise zoom factor will reduce/increase thumbnail size
        clonedElement.style.transform = ""

        let canvasNodeList = element.querySelectorAll("canvas")
        canvasNodeList.forEach(c => {
            let canvasImage = Draw.cloneCanvas(c)
            let canvasElementClone = clonedElement.querySelector("#" + c.id)

            if(canvasElementClone !== null) {
                Util.replaceNode(canvasElementClone as HTMLCanvasElement, canvasImage)
            }
        })
        document.body.appendChild(clonedElement)

        // Remove grabber of selected items
        let grabber = clonedElement.querySelectorAll(".ws-designer-element-grabber")
        for (let i = 0; i < grabber.length; i++) {
            (grabber[i] as HTMLElement).remove();
        }

        // Remove selection border
        let selected = clonedElement.querySelectorAll(".ws-designer-element-selected")
        for (let i = 0; i < selected.length; i++) {
            (selected[i] as HTMLElement).className = (selected[i] as HTMLElement).className.replace("ws-designer-element-selected", "");
        }

        // Remove balloon peak grabber
        grabber = clonedElement.querySelectorAll(".ws-designer-balloon-peak-grabber")
        for (let i = 0; i < grabber.length; i++) {
            (grabber[i] as HTMLElement).remove();
        }

        grabber = clonedElement.querySelectorAll(".ws-designer-textbox-error")
        for (let i = 0; i < grabber.length; i++) {
            (grabber[i] as HTMLElement).className = (grabber[i] as HTMLElement).className.replace("ws-designer-textbox-error", "");
        }

        // Remove textbox resize handler
        let resizer = clonedElement.querySelectorAll(".ws-designer-textbox-resize-handler")
        for (let i = 0; i < resizer.length; i++) {
            (resizer[i] as HTMLElement).remove();
        }

        // Remove textbox resize handler error
        resizer = clonedElement.querySelectorAll(".ws-designer-textbox-resize-handler-error")
        for (let i = 0; i < resizer.length; i++) {
            (resizer[i] as HTMLElement).remove();
        }

        // Remove grabber of selected items
        let toolbars = clonedElement.querySelectorAll("#toolbar-container")
        for (let i = 0; i < toolbars.length; i++) {
            (toolbars[i] as HTMLElement).remove();
        }

        // Remove unpublished images
        let images = clonedElement.querySelectorAll(".ws-designer-element-inactive")
        for (let i = 0; i < images.length; i++) {
            (images[i] as HTMLElement).remove();
        }

        // Remove text exercise variables
        let variables = clonedElement.querySelectorAll(".ws-designer-text-exercise-variables")
        for (let i = 0; i < variables.length; i++) {
            (variables[i] as HTMLElement).remove();
        }

        return clonedElement
    }

    /**
     * Scrolling
     */
    static scrollToPage = (pageKey: string, offset: number, log: (message: string) => void) => {
        let pageElement = document.getElementById(pageKey)
        log("Element " + pageKey + " exists = " + (pageElement !== undefined))
        if (pageElement) {
            let workspace = Util.getParentByClass(pageElement, "ws-designer-sheet-workspace")
            if (workspace) {
                let doc = Util.getParentByClass(workspace, "ws-designer-document")

                if (doc) {
                    log("Scroll to workspace " + workspace.id + " = " + workspace.getBoundingClientRect().top + " + current scroll = " + doc.scrollTop)
                    doc.scroll({
                        top: workspace.getBoundingClientRect().top + doc.scrollTop + offset,
                        behavior: 'smooth'
                    })
                }
            }
        }
    }
    static scrollToPosition = (event: MouseEvent, oldMouseCoords: Coords, classNameOfElement: string) => {
        event.preventDefault()
        let doc = document.getElementsByClassName(classNameOfElement)

        if (doc && doc.length > 0) {
            doc[0].scrollBy({ top: -(event.clientY - oldMouseCoords.y), left: -(event.clientX - oldMouseCoords.x)})
            return new Coords(event.clientX, event.clientY)
        }
    }
    static lastItemResultReached = (amountOfResult: number, defaultPageSize: number, pageSize?: number) => {
        return (pageSize ? pageSize : defaultPageSize) > amountOfResult;
    }

    /** Page */
    static getPagesSorted = (pages: WorksheetPage[]) => {
        return pages.sort((a, b) => a.sort - b.sort)
    }

    /**
     * Group
     */
    static loadGroupStructureToHierarchy = (worksheetItems: WorksheetItem[]) => {
        let result = worksheetItems.filter(wi =>
            ((wi.groupId === undefined || wi.groupId === null) && (wi.groupKey === undefined || wi.groupKey === null)))

        result.forEach(item => {
            WDUtils.setGroupItems(worksheetItems, item)
        })

        return result
    }
    static setGroupItems = (worksheetItems: WorksheetItem[], item: WorksheetItem) => {
        // Copy child elements to group element
        let children = worksheetItems
            .filter(worksheetItem => (worksheetItem.groupId && worksheetItem.groupId.id === item.id) || (worksheetItem.groupKey && worksheetItem.groupKey === item.itemKey))
        if (children !== null && children.length > 0) {
            children.forEach(child => child.groupKey = item.itemKey)
            item.children = Util.cloneArray(children)

            // Recursive call for massive destruction
            item.children.forEach(child => {
                WDUtils.setGroupItems(worksheetItems, child)
            })
        }
    }

    /**
     * Z-Index
     * */
    static getMaxPosZOfElements = (elements: WorksheetItem[]): number => {
        if (elements === null || elements === undefined || elements.length === 0) {
            return 0
        }

        return elements.map(e => e.posZ || 0).reduce((prev, current) => {
            return ((prev < current) ? current : prev)
        })
    }

    /**
     * Positioning Lines
     */
    static EvalPositioningLines(mode: PositioningMode, evalElement: ElementLayout, refItem: ElementLayout,
                                positioningLines: PositioningLine[], check: PositioningLineCheck, zoom: number) : ElementLayout {
        const lineOffset = 20
        const threshold = 13
        const borderWidth = 0.5

        let leftFound = false, topFound = false

        let result: ElementLayout = new ElementLayout(refItem.left, refItem.top, refItem.width, refItem.height)

        let startY = Math.min(refItem.top, evalElement.top) - lineOffset,
            endY = Math.max(refItem.top + refItem.height, evalElement.top + evalElement.height) + lineOffset,
            startX = Math.min(refItem.left, evalElement.left) - lineOffset,
            endX = Math.max(refItem.left + refItem.width, evalElement.left + evalElement.width) + lineOffset
        let line: PositioningLine | undefined

        // x-axis left border
        if (check.left && evalElement.left - threshold <= refItem.left && evalElement.left + threshold >= refItem.left) {
            line = positioningLines
                .find(p => p.type === PositioningLineType.HORIZONTAL_START)

            if (line === undefined) {
                positioningLines.push(new PositioningLine(
                    new Coords(evalElement.left - borderWidth, startY),
                    new Coords(evalElement.left - borderWidth, endY),
                    PositioningLineType.HORIZONTAL_START
                ))
            }
            else {
                line.pos1.y = Math.min(line.pos1.y, startY)
                line.pos2.y = Math.max(line.pos2.y, endY)
            }

            result.left = evalElement.left - borderWidth
            leftFound = true
        }

        // x-axis right border
        if (check.right &&
            evalElement.left + evalElement.width - threshold <= refItem.left + refItem.width &&
            evalElement.left + evalElement.width + threshold >= refItem.left + refItem.width) {

            line = positioningLines
                .find(p => p.type === PositioningLineType.HORIZONTAL_END)

            let x = evalElement.left + evalElement.width - borderWidth

            if (line === undefined) {
                positioningLines.push(new PositioningLine(
                    new Coords(x, startY),
                    new Coords(x, endY),
                    PositioningLineType.HORIZONTAL_END
                ))
            }
            else {
                line.pos1.y = Math.min(line.pos1.y, startY)
                line.pos2.y = Math.max(line.pos2.y, endY)
            }

            // Reposition to right border only if left border was not found
            if (mode === PositioningMode.RESIZE) {
                result.width = x - refItem.left
            }
            else if (!leftFound) {
                result.left = x - refItem.width
            }
        }

        // y-axis top border
        if (check.top && evalElement.top - threshold <= refItem.top && evalElement.top + threshold >= refItem.top) {
            line = positioningLines
                .find(p => p.type === PositioningLineType.VERTICAL_START)

            if (line === undefined) {
                positioningLines.push(new PositioningLine(
                    new Coords(startX, evalElement.top - borderWidth),
                    new Coords(endX, evalElement.top - borderWidth),
                    PositioningLineType.VERTICAL_START
                ))
            }
            else {
                line.pos1.x = Math.min(startX, line.pos1.x)
                line.pos2.x = Math.max(endX, line.pos2.x)
            }

            result.top = evalElement.top
            topFound = true
        }

        // y-axis bottom border
        if (check.bottom &&
            evalElement.top + evalElement.height - threshold <= refItem.top + refItem.height &&
            evalElement.top + evalElement.height + threshold >= refItem.top + refItem.height) {

            line = positioningLines
                .find(p => p.type === PositioningLineType.VERTICAL_END)

            if (line === undefined) {
                positioningLines.push(new PositioningLine(
                    new Coords(startX, evalElement.top + evalElement.height),
                    new Coords(endX, evalElement.top + evalElement.height),
                    PositioningLineType.VERTICAL_END
                ))
            }
            else {
                line.pos1.x = Math.min(startX, line.pos1.x)
                line.pos2.x = Math.max(endX, line.pos2.x)
            }

            // Reposition to bottom border only if top border was not found
            if (mode === PositioningMode.RESIZE) {
                result.height = evalElement.top + evalElement.height - refItem.top
            }
            else if (!topFound) {
                result.top = evalElement.top + evalElement.height - refItem.height
            }
        }

        return result;
    }
    static ClearPositioningLines = () => {
       let elements = document.querySelectorAll(".ws-designer-sheet-canvas")
        for (let i = 0; i < elements.length; i++) {
            const canvas = elements[i] as HTMLCanvasElement

            let draw = new Draw(canvas)
            draw.Clear()
        }
    }
    static DrawPositioningLines = (pageKey: string, positioningLines: PositioningLine[]) => {
        positioningLines.forEach(p => {
            WDUtils.DrawPositioningLine(pageKey, p.pos1, p.pos2)
        })
    }
    static DrawPositioningLine = (pageKey: string, p1: Coords, p2: Coords) => {
        let canvas = document.getElementById(pageKey + "-canvas") as HTMLCanvasElement
        if (canvas) {
            let draw = new Draw(canvas)

            draw.setBorder(new DrawBorder(Const.COLOR_RED, DrawBorderStyle.round, 1))
            draw.setLineStyle(DrawLineStyle.dotted)
            draw.setFillColor("transparent")

            draw.Line(p1, p2)
        }
    }
}
